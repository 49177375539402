<template>
<div>mysql</div>
</template>

<script>
export default {
  name: "Mysql"
}
</script>

<style scoped>

</style>